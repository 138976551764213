/* Other CSS rules... */

/* Utility Classes */
.cursor-pointer {
  cursor: pointer;
}
.CU{
  padding-bottom: 20px;
  text-align: center;
}

/* Contact Page Styles */
.contacts-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 60vh;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  text-align: center; /* Center the text within the section */
}

.contact-title {
  font-size: 2.5rem;
  color: black;
  margin-bottom: 20px;
}

.contact-form {
  background-color: #fff;
  padding: 20px;
  width: 100%; /* Adjusted form width */
  max-width: 1000px; /* Increase max-width to make the form larger */
  margin: 0 auto;
  background-color: transparent;
}

.contact-form h3 {
  font-size: 1.75rem;
  margin-bottom: 20px;
  color: #333;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  padding: 15px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid black;
  background: transparent;
  font-size: 1.25rem;
  width: 100%;
  color: black; /* Set text color to white */
  transition: border-bottom-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-bottom: 2px solid white;
  outline: none;
}

.contact-form textarea {
  height: 150px;
}

.button-container {
  display: flex;
  align-items: center;
}

.contact-form button {
  position: relative; /* Required for positioning the animation */
  padding: 15px 46%; /* Adjust button size */
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 1.25rem;
  cursor: pointer;
  overflow: hidden; /* Hide animation overflow */
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
}

.contact-form button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -60px; /* Start off-screen */
  width: 60px; /* Adjusted width */
  height: 60px; /* Adjusted height */
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: translateY(-50%);
  animation: loading-animation 1s infinite;
  opacity: 0; /* Hidden initially */
}

.contact-form button.loading:before {
  opacity: 1; /* Show animation when loading */
}

.contact-form button.hidden {
  color: transparent; /* Hide text when loading */
}

.contact-form button.hidden:before {
  display: none; /* Hide animation when text is hidden */
}

.contact-form button:hover {
  background-color: rgba(0, 0, 0, 0.747);
}

@keyframes loading-dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@keyframes loading-animation {
  0% {
    left: -60px; /* Start from the left */
  }
  100% {
    left: 100%; /* Move to the right */
  }
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .contact-form {
    padding: 15px;
    max-width: 100%;
  }

  .contact-form h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1rem;
  }

  .contact-form button {
    font-size: 1rem;
    padding: 10px 20px; /* Adjust button size for smaller screens */
    width: 100%;
  }
}
/* Add these new styles for the dropdown (select) element */
.contact-form select {
  padding: 15px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid black;
  background: rgba(255, 255, 255, 0); /* Semi-transparent background */
  font-size: 1.25rem;
  width: 100%;
  color: black; /* Text color */
  transition: border-bottom-color 0.3s ease, background-color 0.3s ease;
  appearance: none; /* Remove default arrow styling for better control */
}

.contact-form select:focus {
  border-bottom: 2px solid white;
  outline: none;
  background: rgba(255, 255, 255, 0.5); /* Slightly less transparent on focus */
}

/* Custom arrow for the dropdown */
.contact-form select::-ms-expand {
  display: none; /* Remove default arrow in IE */
}

.contact-form select::after {
  content: '▼';
  font-size: 1rem;
  color: black;
  position: absolute;
  right: 15px;
  top: calc(50% - 0.5rem); /* Center arrow vertically */
  pointer-events: none;
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .contact-form select {
    font-size: 1rem;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .contact-form select {
    font-size: 0.875rem;
    padding: 8px;
  }
}


@media (max-width: 480px) {
  .contact-title {
    font-size: 1.75rem;
    margin-bottom: 10px;
  }
  
  .contact-form {
    padding: 10px;
    max-width: 100%;
  }

  .contact-form h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.875rem;
  }

  .contact-form button {
    font-size: 0.875rem;
    padding: 8px 15px; /* Adjust button size for even smaller screens */
  }
}
