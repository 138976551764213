/* Base styling for the footer */
.footer {
  background-color: transparent;
  padding: 20px;
  text-align: center;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Faster transition */
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
  pointer-events: none; /* Disable pointer events when hidden */
}

/* Show the footer when it should be visible */
.footer.show {
  opacity: 1;
  pointer-events: auto; /* Enable pointer events when shown */
  transform: translateY(0); /* Ensure the footer is visible */
}

/* Adjust the footer content */
.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%; /* Adjusted padding for responsiveness */
  color: black; /* Set text color to black */
}

.quick-links {
  display: flex;
  flex-direction: column; /* Stack links vertically */
  align-items: center;
}

.quick-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
}

.footer-text {
  font-size: 14px; /* Slightly reduced font size for better readability */
  font-weight: 500;
  text-align: center;
  margin-bottom: 8px; /* Reduced margin for better spacing */
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
  font-size: medium;
  color: black; /* Set text color to black */
}

.footer-link {
  color: black; /* Set link color to black */
  text-decoration: none;
  font-weight: 500;
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
  margin-bottom: 10px; /* Spacing between links */
  display: inline-block;
  border-color: transparent;
  background-color: transparent;
  font-size: large;
}

.footer-link:hover {
  color: #555; /* Adjusted hover color for better contrast */
  transition: color 0.3s ease;
}

.button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
}

.button:hover {
  background-color: #555;
}

.contact-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  text-align: left;
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
}

.google-map-link,
.phone-link {
  color: black; /* Set link color to black */
  text-decoration: none;
}

.google-map-link:hover,
.phone-link:hover {
  color: #555; /* Adjusted hover color for better contrast */
  transition: color 0.3s ease;
}

.company-details {
  margin-top: 20px;
}

.company-text {
  font-size: 12px;
  color: black; /* Set text color to black */
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
}
