.history-page {
  padding: 20px;
  font-family: Raleway, sans-serif;
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
}

.history-page h1 {
  margin-top: 3%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 2.2rem;
  color: black;
}

.history-page p {
  margin-bottom: 20px;
  color: black;
  font-size: 1.2rem;
  text-align: center; /* Center align text */
  margin-right: 20%;
}

/* Container for images and text */
.image-text-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row; /* Default direction */
}

/* Image styling */
.image-text-container img {
  max-width: 30%; /* Adjust the size as needed */
  height: auto;
  border-radius: 80px; /* Base border-radius */
  margin-right: 20px; /* Space between image and text */
  margin-left: 12%; /* Space from the left */
  pointer-events: none; /* Disable all mouse interactions */
}

/* Style adjustments for .middle-image-right */
.image-text-container.middle-image-right {
  margin-right: 8%; /* Space between image and text */
  margin-left: 12%; /* Space from the left */
  margin-top: 5%;
  margin-bottom: 5%;
}

/* Stack images and text vertically on smaller screens, alternating layout */
@media (max-width: 768px) {
  .image-text-container {
    flex-direction: column; /* Stack vertically */
    align-items: center; /* Center items in the container */
    margin-left: 0; /* Reset left margin for vertical layout */
    margin-right: 0; /* Reset right margin for vertical layout */
  }

  .image-text-container:nth-of-type(odd) {
    /* Image first for odd-numbered containers */
    flex-direction: column-reverse; /* Reverse order for odd-numbered containers */
  }

  .image-text-container img {
    margin: 0 0 20px 0; /* Remove margin-left, add margin-bottom */
    max-width: 80%; /* Adjust size for smaller screens */
  }

  .image-text-container.middle-image-right img {
    margin: 0 0 20px 0; /* Same adjustment for middle-image-right */
  }
}

/* Conclusion section styling */
.conclusion {
  padding: 20px;
  margin-top: 5%;
  margin-left: 12%;
  margin-right: 12%;
  text-align: center; /* Centered text */
}

.conclusion p {
  font-size: 1.2rem;
  color: black;
  line-height: 1.6; /* Improve readability */
  margin: 0;
}

/* Logo image styling */
.logo-image {
  max-width: 50%; /* Adjust the size to make the logo smaller */
  height: auto;
  margin-top: 20px; /* Add some space above the logo */
  margin-bottom: -15%; /* Add some space below the logo */
}

/* Responsive design adjustments for image border-radius and max-width */
@media (max-width: 1200px) {
  .image-text-container img {
    border-radius: 8px; /* Slightly smaller border-radius */
  }

  .logo-image {
    max-width: 20%; /* Adjust size for medium screens */
  }
}

@media (max-width: 992px) {
  .image-text-container img {
    border-radius: 6px; /* Smaller border-radius */
    max-width: 40%; /* Increase max-width for smaller screens */
  }

  .logo-image {
    max-width: 25%; /* Adjust size for smaller screens */
  }
}

@media (max-width: 768px) {
  .image-text-container img {
    border-radius: 4px; /* Even smaller border-radius */
    max-width: 50%; /* Adjust max-width to fit smaller screens */
  }

  .logo-image {
    max-width: 30%; /* Further adjust size for smaller screens */
  }
}

@media (max-width: 576px) {
  .image-text-container img {
    border-radius: 2px; /* Minimal border-radius for very small screens */
    max-width: 60%; /* Further increase max-width */
  }

  .logo-image {
    max-width: 40%; /* Further adjust size for very small screens */
  }
}

.image-text-container .text {
  flex: 1; /* Allows the text to take the remaining space */
}
