.about-page {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
}


.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-placeholder,
.right-placeholder {
  width: 500px; /* Fixed width for the placeholders */
}

.placeholder-image {
  max-width: 100%;
  height: auto; /* Ensures the image fits within the placeholder */
  object-fit: cover;
  border-radius: 15px;
}

.about-section {
  flex-grow: 1; /* Takes up the remaining space */
  padding: 20px;
  color: #333;
  text-align: center; /* Center text within the section */
}

.content {
  display: inline-block; /* Makes the content block inline for centering */
  text-align: left; /* Aligns text within the content block */
  max-width: 800px; /* Optional: sets a max-width for readability */
  margin: 0 auto; /* Centers the content block horizontally */
}

.content h2 {
  font-size: 36px;
  margin-bottom: 20px;
  color: black;
}

.content p {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.6;
}
