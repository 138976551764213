/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-right: 5%;
  padding-left: 0.5%;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
  z-index: 10;
  position: fixed;
  width: 100%;
}

/* Navbar styles when on home page */
.home-navbar {
  justify-content: center; /* Center nav-links on the home page */
}

/* Logo container styles */
.logo-container {
  padding-top: 5px;
  margin-right: 34%; /* Adjust margin if needed */

}

/* Logo styles */
.logo {
  max-height: 80px; /* Increase logo size */
  margin-top: -23px; /* Adjust this value to raise the logo */
  position: fixed;
  padding-top: 8px;
  border-radius: 10px;
}

/* Navigation links styles */
.nav-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding-left: 28%;
}

.nav-link {
  color: black; /* Darker text color for better readability */
  text-decoration: none;
  font-weight: 500; /* Font weight adjusted for consistency */
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
  padding: 8px 16px; /* Add padding to create clickable area */
  border: 1px solid transparent; /* Transparent border to match the background */
  background-color: transparent;
  font-size: large;
}

.nav-link:hover {
  color: #888; /* Adjusted hover color for better contrast */
  transition: color 0.3s ease; /* Smooth transition for color change on hover */
}

/* Button styles */
.button {
  background-color: #333; /* Dark background for button */
  border-radius: 50px; /* Rounded button corners */
  color: white; /* White text color */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.6s ease, color 0.6s ease; /* Smooth transition for background and text color */
  font-weight: 500; /* Font weight adjusted for consistency */
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
}

/* Profile Section Styles */
.right-section {
  display: flex;
  align-items: center; /* Center items */
  gap: 10px; /* Add gap between Contact Us and Profile */
}

.profile-container {
  position: relative;
  display: inline-block;
}

.profile-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile-icon {
  border-radius: 50%;
  width: 50px; /* Increased size */
  height: 50px; /* Ensure aspect ratio is maintained */
  margin-right: 10px;
}

.profile-name {
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
}

/* Dropdown Menu Styles */
.profile-menu {
  position: absolute;
  top: 60px; /* Adjusted to accommodate the larger profile icon */
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.profile-menu-item {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
  font-family: 'Raleway', sans-serif;
  cursor: pointer;
  border: none;
  background: none;
}

.profile-menu-item:hover {
  background-color: #f5f5f5;
}

/* Media Query for Tablets */
@media (max-width: 1024px) {
  .right-section{
    flex-direction: column; /* Stack Contact Us and Profile */

  }
  .logo-container {
    display: none; /* Hide logo container on tablets */
  }
  .navbar {
    position: relative;
  }

  .logo {
    display: none; /* Hide logo on tablets */
  }

  .navbar {
    flex-direction: column; /* Stack items vertically */
    padding: 10px;
  }

  .nav-links {
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center align links */
    width: 100%; /* Full width */
    padding-left: 0; /* Remove left padding */
    margin: 0; /* Remove margin */
  }

  .menu-button {
    display: block; /* Show menu button on tablets */
  }

  .button {
    margin-top: 10px; /* Add margin to separate from nav-links */
    align-items: center;
  }
}

/* Additional styles for smaller screens */
@media (max-width: 768px) {
  .navbar img {
    max-width: 100%; /* Adjust size if necessary */
  }

  .navbar {
    position: relative;
  }

  .logo-container {
    display: none; /* Hide logo container on smaller screens */
  }

  .logo {
    display: none; /* Hide logo on smaller screens */
  }

  .nav-links {
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center align links */
    width: 100%; /* Full width */
    padding-left: 0; /* Remove left padding */
    margin: 0; /* Remove margin */
  }

  .button {
    margin-top: 10px; /* Add margin to separate from nav-links */
    display: block; /* Ensure button is displayed */
    align-self: center; /* Center button horizontally */
  }

  .nav-link {
    font-size: 16px; /* Adjust font size for smaller screens */
    padding: 10px 15px; /* Adjust padding for better touch targets */
  }

  .button {
    font-size: 14px; /* Adjust button font size */
    padding-right: 20px;
  }

  .right-section {
    flex-direction: column; /* Stack Contact Us and Profile */
    align-items: center; /* Center items */
    gap: 10px; /* Add gap between Contact Us and Profile */
  }
}
