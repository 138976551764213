/* src/styles/NotFound.css */
.not-found {
  text-align: center;
  padding: 50px;
}

.not-found h1 {
  font-size: 5rem;
  color: #ff0000;
}

.not-found p {
  font-size: 1.5rem;
}

.not-found button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.not-found button:hover {
  background-color: #0056b3;
}
