/* src/styles/Dashboard.css */

.dashboard-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 5%;
}

h2 {
  margin-top: 20px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
}

/* Form styling */
.product-form,
.edit-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the form */
  width: 80%;
  max-width: 800px; /* Optional: max width of the form */
  height: auto; /* Adjusted to auto height to fit content */
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto; /* Allow scrolling if the content is too long */
  z-index: 1000; /* Ensure it's above the overlay */
}

.product-form input,
.edit-form input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.product-form button,
.edit-form button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.product-form button:hover,
.edit-form button:hover {
  background-color: #0056b3;
}

/* Button container for edit form */
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.remove-image-button {
  background-color: #dc3545; /* Red background for remove button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.remove-image-button:hover {
  background-color: #c82333;
}

.save-changes-button {
  background-color: #007bff; /* Blue background for save button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.save-changes-button:hover {
  background-color: #0056b3;
}

.cancel-button {
  background-color: #f44336; /* Red background for cancel button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  position: absolute; /* Positioning button within the form */
  right: 20px;
  top: 20px;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 5%; /* Reduced padding for better fit */
  text-align: center;
  cursor: pointer;
  width: 100%; /* Fill the width of the form */
  box-sizing: border-box; /* Include padding in width calculation */
  margin-bottom: 20px;
}

.dropzone p {
  margin: 0;
}

.image-preview-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between image preview and buttons */
}

.image-preview {
  max-width: 100%; /* Ensure image fits within its container */
  max-height: 200px; /* Optional: max height for images */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 4px;
  margin-top: 10px;
}

.product-list {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 20px;
}

.product-item {
  display: flex;
  align-items: center; /* Align items centrally */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-image {
  margin-right: 15px; /* Space between image and text */
}

.product-image img {
  width: 80px; /* Fixed width for images */
  height: 80px; /* Fixed height for images */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 8px;
}

.product-details {
  display: flex;
  flex-direction: column; /* Stack title and description vertically */
  flex: 1;
}

.product-title {
  font-size: 1.2em;
  font-weight: bold;
}

.product-description {
  font-size: 1em;
  color: #555;
  margin-top: 5px;
}

.product-actions {
  margin-top: 10px;
}

.product-actions button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
}

.product-actions .edit-button {
  background-color: #28a745;
}

.product-actions .delete-button {
  background-color: #dc3545;
}

.product-actions .edit-button:hover {
  background-color: #218838;
}

.product-actions .delete-button:hover {
  background-color: #c82333;
}

.no-products {
  text-align: center;
  font-size: 1.2em;
  color: #888;
}
