/* Search bar container styling */
.search-bar-container {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center align items vertically */
  margin: 20px 0; /* Space above and below the search bar */
  justify-content: center; /* Align items to the right */
}

/* Search bar input field styling */
.search-bar {
  width: 100%; /* Full width of the container */
  max-width: 750px; /* Maximum width for large screens */
  padding: 10px 15px; /* Padding inside the input */
  font-size: 1rem; /* Font size for the input text */
  border: 1px solid #ddd; /* Light border for the input */
  border-radius: 80px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

/* Search bar focus styling */
.search-bar:focus {
  border-color: #333; /* Darker border color on focus */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Darker shadow on focus */
}

/* Placeholder text styling */
.search-bar::placeholder {
  color: #888; /* Placeholder text color */
  font-style: italic; /* Italicize placeholder text */
}



/* General styles for the page */
.products-services-page {
  padding: 20px; /* Add padding for spacing */
  font-family: Arial, sans-serif; /* Set font for the page */
  line-height: 1.6; /* Improve readability */
}

/* Header section styling */
.header-section {
  margin-bottom: 40px; /* Space below the header section */
  text-align: center; /* Center align text */
}

.header-section h1 {
  margin-top: 5%;
  font-size: 2.5rem; /* Adjust heading size */
  margin-bottom: 10px; /* Space below heading */
}

.header-section p {
  font-size: 1.1rem; /* Adjust font size for readability */
  color: #333; /* Set text color */
}

/* Container for product/service section */
.product-service-container {
  background-color: #fff; /* White background */
  padding: 50px; /* Add padding inside the container */
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 30px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
  max-height: 60vh; /* Limit the height to 60% of viewport height */
  overflow: auto; /* Enable scrolling if content overflows */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap into multiple lines */
  gap: 20px; /* Space between items */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.product-service-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.product-service-container {
  scrollbar-width: none; /* Hide scrollbar */
}

/* Optional: Hide scrollbar for Edge */
.product-service-container {
  -ms-overflow-style: none; /* Hide scrollbar */
}

/* Individual product/service styling */
.product-service {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center align items horizontally */
  background: #f9f9f9; /* Light background for contrast */
  padding: 10px; /* Padding inside the box */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
  transition: transform 0.3s ease; /* Smooth transition for scaling */
  cursor: pointer; /* Pointer cursor */
  width: 200px; /* Fixed width for uniform boxes */
  height: 300px; /* Fixed height for uniform boxes */
}

/* Scale up on hover */
.product-service:hover {
  transform: scale(1.03); /* Scale up slightly */
}

/* Image styling for products/services */
.product-service img {
  width: 100%; /* Make the image fill the width of the box */
  height: 60%; /* Occupy 60% of the box height */
  object-fit: cover; /* Maintain aspect ratio and fill the container */
  border-radius: 8px; /* Rounded corners */
}

/* Title styling */
.product-service h2 {
  font-size: 1.2rem; /* Adjust heading size */
  margin-top: 10px; /* Space above the title */
  margin-bottom: 0; /* Remove margin below the title */
  text-align: center; /* Center align the title */
}

/* Enlarged view styling */
.enlarged-view {
  position: fixed; /* Position it fixed to overlay on top */
  top: 50%; /* Center it vertically */
  left: 50%; /* Center it horizontally */
  transform: translate(-50%, -50%) scale(0.9); /* Start slightly scaled down */
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  background: #f9f9f9; /* Light background for contrast */
  padding: 20px; /* Padding around the content */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slightly darker shadow */
  z-index: 1000; /* Ensure it overlays other content */
  width: 70vw; /* Set width to 70% of viewport width */
  height: 70vh; /* Set height to 70% of viewport height */
  overflow: hidden; /* Hide overflow to maintain layout */
  opacity: 0; /* Start with invisible */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition */
}

/* Enlarged view visible state */
.enlarged-view.visible {
  opacity: 1; /* Fully visible */
  transform: translate(-50%, -50%) scale(1); /* Scale up to normal size */
}

/* Enlarged image styling */
.enlarged-image {
  width: 50%; /* Image takes up half of the enlarged view width */
  height: 100%; /* Image fills the full height of the enlarged view */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners */
}

/* Enlarged text content styling */
.enlarged-text-content {
  width: 50%; /* Text content takes up the other half of the enlarged view width */
  display: flex;
  flex-direction: column; /* Stack header and description vertically */
  padding: 20px; /* Add padding inside text content */
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Header for enlarged view */
.enlarged-text-content h2 {
  font-size: 2rem; /* Larger heading size */
  margin-top: 0; /* Remove top margin */
  margin-bottom: 10px; /* Space below heading */
}

/* Paragraph text in enlarged view */
.enlarged-text-content p {
  font-size: 1.2rem; /* Larger font size for the description */
  color: #666; /* Set text color */
  margin: 0; /* Remove margin for consistent alignment */
  padding-top: 10px; /* Add space above paragraphs */
}

/* Overlay for background */
.overlay {
  position: fixed; /* Position fixed to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
  z-index: 999; /* Ensure it sits below the enlarged view but above other content */
  pointer-events: auto; /* Enable pointer events to block interaction */
}

/* Disable interaction with the background */
body.modal-open {
  overflow: hidden; /* Prevent scrolling */
  position: fixed; /* Fix the position */
  width: 100%; /* Ensure full width */
}

/* Close button styling */
.close-button {
  position: absolute; /* Position it at the top right */
  top: 10px;
  right: 10px;
  background: #333; /* Dark background for visibility */
  color: #fff; /* White text color */
  border: none; /* Remove border */
  border-radius: 50%; /* Circular button */
  width: 30px; /* Button size */
  height: 30px; /* Button size */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem; /* Larger font size for the "X" */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.close-button:hover {
  background: #555; /* Slightly lighter background on hover */
}

/* Footer section styling */
.footer-section {
  margin-top: 40px; /* Space above the footer section */
  text-align: center; /* Center align text */
}

.footer-section p {
  font-size: 1.1rem; /* Adjust font size */
  color: #333; /* Set text color */
  margin-bottom: 20px; /* Space below paragraph */
}

/* Contact button styling */
.contact-button {
  background-color: Black; /* Initial background color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Add padding */
  font-size: 1rem; /* Font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.8s ease; /* Smooth transition for background color */
}

.contact-button:hover {
  background-color: transparent; /* Transparent background color on hover */
  color: Black; /* Optional: Change text color to contrast with the transparent background */
}

/* Responsive Styles */
/* Responsive Styles */
@media (max-width: 1024px) {
  .search-bar-container {
    padding: 0 2%; /* Add some padding for small screens */
    justify-content: center; /* Center the search bar container */
  }

  .product-service-container {
    padding: 10px;
  }

  .product-service {
    width: calc(100% - 20px); /* Full width with gaps */
  }

  .enlarged-view {
    width: 80vw; /* Use viewport width for scaling */
    height: auto; /* Adjust height to maintain aspect ratio */
    max-width: 600px; /* Constrain max-width */
    max-height: 600px; /* Constrain max-height */
    padding: 20px; /* Add padding for better content fitting */
    display: flex; /* Flexbox layout for content */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    overflow: auto; /* Allow scrolling if content overflows */
  }

  .enlarged-image {
    width: 100%; /* Image takes full width of the container */
    max-height: 50%; /* Constrain image height to 50% of container height */
    object-fit: cover; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners */
  }

  .enlarged-text-content {
    width: 100%; /* Text content takes full width of the container */
    padding: 20px; /* Add padding inside text content */
    box-sizing: border-box; /* Ensure padding is included in width */
    text-align: center; /* Center align text */
  }
}

@media (max-width: 768px) {
  .header-section h1 {
    font-size: 2rem;
  }

  .header-section p {
    font-size: 1rem;
  }

  .product-service {
    width: 100%;
    height: auto; /* Adjust height for mobile */
  }

  .enlarged-view {
    width: 90vw; /* Use viewport width for scaling */
    height: auto; /* Adjust height to maintain aspect ratio */
    max-width: 500px; /* Constrain max-width for smaller screens */
    max-height: 500px; /* Constrain max-height for smaller screens */
    padding: 15px; /* Add padding for better content fitting */
    display: flex; /* Flexbox layout for content */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center content horizontally */
    justify-content: center; /* Center content vertically */
    overflow: auto; /* Allow scrolling if content overflows */
  }

  .enlarged-image {
    width: 100%; /* Image takes full width of the container */
    max-height: 50%; /* Constrain image height to 50% of container height */
    object-fit: cover; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners */
  }

  .enlarged-text-content {
    width: 100%; /* Text content takes full width of the container */
    padding: 10px; /* Add padding inside text content */
    box-sizing: border-box; /* Ensure padding is included in width */
    text-align: center; /* Center align text */
  }

  .search-bar {
    font-size: 0.9rem;
  }
}
