/* General background styling */
body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('./Background.jpg') no-repeat center center; /* Background image */
  background-size: cover; /* Ensure the image covers the entire background */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

/* General scrollbar styling for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Width of the vertical scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

/* Track (the part of the scrollbar that is not draggable) */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Light transparent background for the track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Darker transparent background for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7); /* Darker transparent color on hover */
}

/* Scrollbar for horizontal scrollbars */
::-webkit-scrollbar-horizontal {
  height: 12px; /* Height of horizontal scrollbar */
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5); /* Darker transparent background for horizontal thumb */
  border-radius: 10px; /* Rounded corners for horizontal thumb */
}

/* Scrollbar styling for Firefox */
html {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1); /* Thumb and track color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
  text-align: center;
  font-size: 24px; /* Adjust size as needed */
  /* Add other styles as needed */
}

.button {
  background-color: black;
  border-radius: 8px;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.6s ease;
  font-weight: 530;
  font-family: 'Raleway', sans-serif; /* Apply Raleway font */
}

.button:hover {
  background-color: #d4d4d4;
  color: black;
}
