/* Reset default margin and padding for all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none; /* Prevent selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
}

/* Body styling */
body {
  font-family: Raleway, sans-serif;
  line-height: 1.6;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  color: black; /* Set text color to black */
}

.overlay-logo{
  max-width: 40%;
  border-radius: 30px;
}
/* Home page container */
.home {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column; /* Ensure children are stacked vertically */
  padding: 0; /* No padding */
  overflow: hidden; /* Hide any overflow */
}

/* Video background styling */
.video-background {
  position: relative; /* Fixed position to keep it in place */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Adjust height as needed */
  overflow: hidden;
  z-index: -1; /* Ensure video stays behind content */
  margin-bottom: 0; /* Remove margin */
}

/* Video element styling */
.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the video within the container */
}

/* Arrow styling */
.scroll-down-arrow {
  display: inline-block;
  margin-top: 3%; /* Increase space between button and arrow */
  cursor: pointer; /* Pointer cursor to indicate clickability */
  background: transparent; /* Ensure background is transparent */
  border: none; /* Remove default border */
  padding: 0; /* Remove padding */
  outline: none; /* Remove default outline */
}

.arrow {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  border-left: 3px solid white;
  border-top: 3px solid white;
  background: transparent;
  transform: rotate(225deg); /* Create a downward facing arrow */
  display: inline-block;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

/* Arrow hover effect */
.scroll-down-arrow:hover .arrow {
  transform: rotate(225deg) translateX(5px) translateY(5px); /* Slight move down on hover */
  scale: 110%;
}

/* Ensure smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Overlay section styling */
.overlay-section {
  position: absolute; /* Absolute positioning to overlay on top of video */
  top: 50px;
  left: 0;
  width: 100%;
  height: 120vh; /* Adjust height to cover the viewport */
  background: rgba(0, 0, 0, 0); /* Semi-transparent dark overlay for better text readability */
  color: white; /* White text color */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  z-index: 1; /* Ensure it stays above the video */
}

/* Overlay section title and description */
.overlay-section h1,
.overlay-section p {
  margin: 10px 0; /* Space between elements */
}

.overlay-section h1 {
  font-size: 4rem; /* Adjust size as needed */
  margin-bottom: 2%;
}

.overlay-section p {
  font-size: 1rem; /* Adjust size as needed */
  max-width: 580px; /* Limit the width for readability */
}

/* Button styling in the overlay section */
.learn-more-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: transparent; /* Transparent background */
  border: 2px solid white; /* White border with 2px width */
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.learn-more-button:hover {
  color: black; /* Change text color on hover */
  background-color: white; /* Set background color on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Content container */
.content {
  width: 100%;
  padding: 20px; /* Add padding to content */
  background: transparent; /* Make background transparent */
  border: none; /* Remove border */
  box-sizing: border-box; /* Include padding in the width calculation */
  z-index: 1; /* Ensure content stays above video */
  margin-top: 60vh; /* Adjust margin to push content below the video */
}

/* Section headings */
.content h1, 
.content h2, 
.content h3, 
.content h4, 
.content h5, 
.content h6 {
  font-size: 1.5em;
  color: black; /* Set header color to black */
  word-wrap: break-word; /* Break long words */
  text-align: center;
}

/* Section paragraphs */
.content .section {
  margin-bottom: 20px; /* Add space between sections */
  color: black; /* Set paragraph color to black */
  word-wrap: break-word; /* Break long words */
}

/* Testimonials blockquote */
.client-testimonials blockquote {
  background: black; /* Dark background for testimonials */
  color: white; /* White text for testimonials */
  padding: 20px; /* Add padding */
  border-radius: 10px; /* Rounded corners */
  margin-top: 10px; /* Add space above */
  margin-bottom: 20px; /* Add space below */
  transition: all 0.3s ease; /* Smooth transition for all changes */
}

/* Hover effect for testimonials blockquote */
.client-testimonials blockquote:hover {
  background: #333; /* Slightly lighter background on hover */
  padding: 30px; /* Enlarge padding */
  font-size: 1.25rem; /* Enlarge text size */
  transform: scale(1.05); /* Slightly enlarge the box */
}

/* About Us and Products & Services sections */
.about-us-container,
.products-services {
  background-color: transparent; /* Revert background to transparent */
  border-radius: 10px; /* Rounded corners */
  color: black; /* Set text color to black */
  text-align: center; /* Center align text */
  margin-bottom: 20px; /* Add space below sections */
}

.products-services h2, 
.about-us-container h2 {
  font-size: 2rem; /* Increase header size */
  color: black; /* Set header color to black */
}

.products-services p, 
.about-us-container p {
  font-size: 1.2rem; /* Increase paragraph size */
  color: black; /* Set paragraph color to black */
  word-wrap: break-word; /* Break long words */
}

/* Contact container */
.contact-container {
  width: 100%;
  padding: 20px 0; /* Add vertical padding */
  background: transparent; /* Transparent background */
}

/* Responsive Design for Tablets and Mobile */
@media (min-width: 768px) and (max-width: 1024px) {
  .scroll-down-arrow {
    margin-top: 3%; /* Adjust space between button and arrow */
  }

  .overlay-section h1 {
    font-size: 3rem; /* Adjust size for tablets */
  }

  .overlay-section p {
    font-size: 0.9rem; /* Adjust size for tablets */
  }

  .learn-more-button {
    font-size: 0.9rem; /* Adjust button text size for tablets */
  }

  .content {
    padding: 15px; /* Adjust padding for tablets */
  }

  .about-us-container, 
  .products-services {
    padding: 15px; /* Adjust padding for tablets */
    margin: 10px auto; /* Adjust margin for tablets */
  }

  .content .section p {
    font-size: 1rem; /* Adjust paragraph size for tablets */
  }

  .products-services h2 {
    font-size: 1.5rem; /* Adjust header size for tablets */
  }

  .products-services p {
    font-size: 1rem; /* Adjust paragraph size for tablets */
  }
}

@media (max-width: 767px) {
  .overlay-section h1 {
    font-size: 2.5rem; /* Adjust size for mobile */
  }

  .overlay-section p {
    font-size: 0.8rem; /* Adjust size for mobile */
  }

  .learn-more-button {
    font-size: 0.8rem; /* Adjust button text size for mobile */
  }

  .scroll-down-arrow {
    margin-top: 20%; /* Adjust space between button and arrow */
  }

  .content {
    padding: 10px; /* Adjust padding for mobile */
    margin-top: 50vh; /* Adjust margin for mobile */
  }

  .about-us-container, 
  .products-services {
    padding: 10px; /* Adjust padding for mobile */
    margin: 10px auto; /* Adjust margin for mobile */
  }

  .content .section p {
    font-size: 0.9rem; /* Adjust paragraph size for mobile */
  }

  .products-services h2 {
    font-size: 1.3rem; /* Adjust header size for mobile */
  }

  .products-services p {
    font-size: 0.9rem; /* Adjust paragraph size for mobile */
  }
}
